import React, {Component, RefObject} from "react";
import L, {LatLng, Marker} from "leaflet";
import {Map} from 'react-leaflet';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import '../styles/searchbar.scss';

// Interface for all the props used by this component
interface SearchProps {
    map: RefObject<Map>
}

class Searchbar extends Component<SearchProps> {

    private readonly _refs = {
        autocompleteInput: React.createRef<HTMLInputElement>(),
    }

    private autocomplete: google.maps.places.Autocomplete | undefined;
    private activeMarker: Marker | undefined;

    private get map() {
        return this.props.map.current?.leafletElement;
    }

    /**
     * Class constructor for setting default class property values.
     * @param props
     */
    constructor(props: SearchProps) {
        super(props);

        // Set default icon values (default one seems to be missing?)
        L.Marker.prototype.options.icon = L.icon({
            iconUrl: icon,
            shadowUrl: iconShadow
        });
    }

    /**
     * This method runs after the component output has been rendered to the DOM.
     */
    componentDidMount() {
        // Options For Autocomplete
        const options: google.maps.places.AutocompleteOptions = {
            types: ['(cities)']
        };

        // Initialize google autocomplete
        this.autocomplete = new google.maps.places.Autocomplete(
            this._refs.autocompleteInput.current!,
            options
        );

        // Bind an event listener to the 'place_changed' event to detect clicks on places from the autocomplete list
        this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
    }

    /**
     * Method to disable map dragging
     */
    disableMapDragging = () => {
        this.map!.dragging.disable();
    }

    /**
     * Method to enable map dragging
     */
    enableMapDragging = () => {
        this.map!.dragging.enable();
    }

    /**
     * Handle changes on the input field when selecting items from the autocomplete list.
     */
    handlePlaceChanged = () => {
        if (!this.autocomplete || !this.map) {
            return;
        }

        const place = this.autocomplete.getPlace();

        if (!place.geometry) {
            return;
        }

        // Create a new LatLng instance from the place coordinates
        const latLong: LatLng = new LatLng(
            place.geometry.location.lat(),
            place.geometry.location.lng()
        );

        // Remove the previous set marker if set
        if (this.activeMarker) {
            this.map.removeLayer(this.activeMarker);
        }

        // Create a new marker on the coordinates of the selected place
        this.activeMarker = new Marker(latLong).addTo(this.map);

        // Move the selected place and created marker into view
        this.map.panTo(latLong);
    }

    render() {
        return (
            <div className="search-bar-container">
                <input
                    ref={this._refs.autocompleteInput}
                    type="text"
                    id="autocomplete"
                    placeholder="Search places..."
                    onMouseOver={this.disableMapDragging}
                    onMouseLeave={this.enableMapDragging}
                />
            </div>
        );
    }
}

export default Searchbar
