import L from "leaflet";

export interface BasicBaseLayerAttributes {
    readonly name: string,
    readonly style: L.PathOptions,
    readonly attribution: string,
    readonly url: string
}

export default class BasicBaseLayer implements BasicBaseLayerAttributes {
    static readonly OPEN_STREET_MAP = new BasicBaseLayer({
        name: "OpenStreetMap",
        style: {
            stroke: true,
            color: "black",
            weight: 1,
            fillOpacity: 0.0
        },
        attribution: "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors &copy; <a href='https://carto.com/attributions'>CARTO</a>",
        url: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
    });
    static readonly OPEN_STREET_MAP_DARK_WHITE = new BasicBaseLayer({
        name: "OpenStreetMap Dark White",
        style: {
            stroke: true,
            color: "white",
            weight: 1,
            fillOpacity: 0.0
        },
        attribution: "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors &copy; <a href='https://carto.com/attributions'>CARTO</a>",
        url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
    });
    static readonly OPEN_STREET_MAP_DARK_YELLOW = new BasicBaseLayer({
        name: "OpenStreetMap Dark Yellow",
        style: {
            stroke: true,
            color: "yellow",
            weight: 1,
            fillOpacity: 0.0
        },
        attribution: "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors &copy; <a href='https://carto.com/attributions'>CARTO</a>",
        url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
    });
    static readonly OPEN_STREET_MAP_DARK_RED = new BasicBaseLayer({
        name: "OpenStreetMap Dark Red",
        style: {
            stroke: true,
            color: "red",
            weight: 1,
            fillOpacity: 0.0
        },
        attribution: "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors &copy; <a href='https://carto.com/attributions'>CARTO</a>",
        url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
    });
    static readonly OPEN_STREET_MAP_DARK_GREEN = new BasicBaseLayer({
        name: "OpenStreetMap Dark Green",
        style: {
            stroke: true,
            color: "green",
            weight: 1,
            fillOpacity: 0.0
        },
        attribution: "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors &copy; <a href='https://carto.com/attributions'>CARTO</a>",
        url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
    });
    static readonly OPEN_STREET_MAP_DARK_BLUE = new BasicBaseLayer({
        name: "OpenStreetMap Dark Blue",
        style: {
            stroke: true,
            color: "blue",
            weight: 1,
            fillOpacity: 0.0
        },
        attribution: "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors &copy; <a href='https://carto.com/attributions'>CARTO</a>",
        url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
    });
    static readonly OPEN_STREET_MAP_DARK_PURPLE = new BasicBaseLayer({
        name: "OpenStreetMap Dark Purple",
        style: {
            stroke: true,
            color: "purple",
            weight: 1,
            fillOpacity: 0.0
        },
        attribution: "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors &copy; <a href='https://carto.com/attributions'>CARTO</a>",
        url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
    });

    public readonly name: string;
    public readonly style: L.PathOptions;
    public readonly attribution: string;
    public readonly url: string;

    private constructor(options: BasicBaseLayerAttributes) {
        this.name = options.name;
        this.style = options.style;
        this.attribution = options.attribution;
        this.url = options.url;
    }
}