import L from 'leaflet'
import {withLeaflet, MapControl, MapControlProps} from 'react-leaflet'
import '../styles/legend.scss';

// List of zoning plan legend items for the "Bestemmingsplangebied" layer.
const zoningPlanLegendItems = [
    { color: "#ebf0d2", name: "Agrarisch" },
    { color: "#d2e1a5", name: "Agrarisch met waarden" },
    { color: "#b45fd2", name: "Bedrijf" },
    { color: "#c8a0d7", name: "Bedrijventerrein" },
    { color: "#62a62c", name: "Bos" },
    { color: "#ffc8be", name: "Centrum" },
    { color: "#ff3c82", name: "Cultuur en ontspanning" },
    { color: "#ffa096", name: "Detailhandel" },
    { color: "#f091be", name: "Dienstverlening" },
    { color: "#ffbe87", name: "Gemengd" },
    { color: "#28c846", name: "Groen" },
    { color: "#ff6923", name: "Horeca" },
    { color: "#cdcdcd", name: "Infrastructuur" },
    { color: "#ebc3d7", name: "Kantoor" },
    { color: "#dc9b78", name: "Maatschappelijk" },
    { color: "#82a591", name: "Natuur" },
    { color: "#b9d746", name: "Recreatie" },
    { color: "#82c846", name: "Sport" },
    { color: "#c8d76e", name: "Tuin"},
    { color: "#cdcdcd", name: "Verkeer" },
    { color: "#ff3c82", name: "Ontspaning en vermaak" },
    { color: "#afcde1", name: "Water" },
    { color: "#ffff00", name: "Wonen" },
    { color: "#ffffb4", name: "Woongebied" },
    { color: "#ebe1eb", name: "Overig" },
];

// Interface for all the props used by this component
interface LegendProps extends MapControlProps {
    isVisible: boolean
}

/**
 * Component for showing a legend at the bottom left of the screen based on the prop.isVisible value.
 */
class Legend extends MapControl<LegendProps> {

    /**
     * Toggle/ update the visibility of the legend each time the props.isVisible changes.
     *
     * @param nextProps the updated props variables
     */
    componentWillReceiveProps(nextProps: LegendProps) {
        if (nextProps.isVisible !== this.props.isVisible) {
            this.toggleVisibility(nextProps.isVisible);
        }
    }

    /**
     * Toggle the visibility of the legend based on the value of the passed boolean by
     * adding or removing the leaflet element as a map control.
     *
     * @param isVisible add the legend as a control when false, else remove it from the map controls.
     * @private
     */
    private toggleVisibility(isVisible: boolean) {
        const { map } = this.props.leaflet as any;

        if (isVisible) {
            map.removeControl(this.leafletElement);
        } else {
            this.leafletElement.addTo(map);
        }
    }

    /**
     * Method to create and return a Leaflet element instance. The instance will be stored in this class as
     * this.leafletElement and used by other methods handling behavior such as events bindings.
     *
     * @param props
     */
    createLeafletElement(props: any) {
        const Legend = L.Control.extend({
            onAdd: () => {
                const list = L.DomUtil.create("ul", "legend-items");
                let listItems: string[] = [];

                // Add each element from the legendItems array as a new html element to the listItems array.
                zoningPlanLegendItems.forEach(item => {
                    listItems.push(`<li><i style="background:${item.color}"/></i><span>${item.name}</span></li>`);
                });

                // Join all listItems and place them inside the created <ul> element.
                list.innerHTML = listItems.join("");

                return list;
            }
        });

        return new Legend({ position: "bottomleft" });
    }

    /**
     * This method runs after the component output has been rendered to the DOM
     */
    componentDidMount() {
        // Call the visibility toggle method after the element has been created to
        // prevent it from showing when the layer is not selected.
        this.toggleVisibility(this.props.isVisible);
    }
}

export default withLeaflet(Legend);
