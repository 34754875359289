import proj4 from "proj4";
import BrkGeodataResponse from "../models/BrkGeodataResponse";
import {LatLngBounds} from "leaflet";

class ApiCallerBrk {
    private static _instance: ApiCallerBrk | undefined;
    
    public static get instance() {
        return this._instance || new ApiCallerBrk();
    }
    
    private constructor() {
    }
    
    async fetchGeoJson(latLngBounds: LatLngBounds): Promise<BrkGeodataResponse> {
        const WGS84 = "WGS84";
        const RDnew = "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +units=m +towgs84=565.2369,50.0087,465.658,-0.406857330322398,0.350732676542563,-1.8703473836068,4.0812 +no_defs";

        const url = new URL("https://geodata.nationaalgeoregister.nl/kadastralekaart/wfs/v4_0");
        const bbox = [
            proj4(WGS84, RDnew, [
                latLngBounds.getSouthWest().lng,
                latLngBounds.getSouthWest().lat
            ]).toString(),
            proj4(WGS84, RDnew, [
                latLngBounds.getNorthEast().lng,
                latLngBounds.getNorthEast().lat
            ]).toString()
        ].join(",");
        url.search = new URLSearchParams({
            version: "2.0.0",
            request: "GetFeature",
            service: "WFS",
            typeName: "kadastralekaartv4:perceel",
            count: "1000",
            outputFormat: "json",
            srsName: "EPSG:4326",
            bbox
        }).toString();

        const response = await fetch(url.href);
        return response.json();
    }
}

export default ApiCallerBrk.instance;