import React, {createRef} from 'react'
import {LayerGroup, LayersControl, Map, TileLayer, WMSTileLayer} from 'react-leaflet';
import L, {GeoJSON, LatLngTuple, LayersControlEvent, LeafletEvent} from "leaflet";
import Legend from "./Legend";
import Feature from "../models/Feature";
import LandPlotPopup from "./LandPlotPopup";
import Searchbar from "./Searchbar";
import {renderToString} from "react-dom/server";
import BasicBaseLayer from "../models/BasicBaseLayer";
import BasicOverlayName from "../models/BasicOverlayName";
import ApiCallerBrk from "../utils/ApiCallerBrk";

import 'leaflet/dist/leaflet.css';

const { BaseLayer, Overlay } = LayersControl;

const SETTINGS = require("../settings.json");

interface MapViewState {
    legendIsVisible: boolean,
}

export default class MapView extends React.Component<{}, MapViewState> {
    private readonly _refs = {
        bagLayer: createRef<LayerGroup>(),
        brkLayer: createRef<LayerGroup>(),
        map: createRef<Map>()
    };

    readonly state: MapViewState = {
        legendIsVisible: false
    };

    private selectedBaseLayer = BasicBaseLayer.OPEN_STREET_MAP;

    constructor(props: any) {
        super(props);
    }

    /**
     * For performing an action on every {@link GeoJSON} object.
     * @param fn callback
     * @private
     */
    private forEachGeoJson (fn: (geoJson: L.GeoJSON) => void) {
        this._refs.map.current?.leafletElement.eachLayer(layer => {
            if (layer instanceof L.GeoJSON) {
                fn(layer);
            }
        });
    }

    /**
     * Handler for move end.
     * @param event
     */
    private moveEndHandler = async (event: LeafletEvent) => {
        const map = this._refs.map.current;
        const brkLayer = this._refs.brkLayer.current;
        if (!map || !brkLayer) {
            return;
        }

        if (map.leafletElement.getZoom() < SETTINGS.layerZoomThreshold) {
            return;
        }

        const responseJson = await ApiCallerBrk.fetchGeoJson(map.leafletElement.getBounds());

        responseJson.features.forEach(feature => {
            // Create geoJson
            const geoJsonObject = L.geoJSON(feature, {
                style: this.selectedBaseLayer.style,
                onEachFeature(feature: Feature, layer) {
                    layer.bindPopup(renderToString(
                        <LandPlotPopup feature={feature}/>
                    ));
                }
            });

            // Add geoJson to layer
            geoJsonObject.addTo(brkLayer.leafletElement);
        });
    }

    /**
     * Handler for zoom end.
     * @param event
     */
    private zoomEndHandler = (event: LeafletEvent) => {
        const zoom = this._refs.map.current?.leafletElement.getZoom();

        if (zoom === undefined) {
            return;
        }

        if (zoom < SETTINGS.layerZoomThreshold) {
           this.forEachGeoJson(geoJson => geoJson.remove());
        }
    }

    private baseLayerChangeHandler = (event: LayersControlEvent) => {
        this.selectedBaseLayer = Object.values(BasicBaseLayer).find(baseLayer => baseLayer.name === event.name);
        this.forEachGeoJson(geoJson => geoJson.setStyle(this.selectedBaseLayer.style));
    }

    /**
     * Show the [Legend] component when the specified layer is enabled from the LayersControl map element.
     *
     * @param layersControlEvent leaflet layers control event with properties from the base Event.
     */
    private addMapLegend = (layersControlEvent: LayersControlEvent) => {
        if (layersControlEvent.name === BasicOverlayName.ZONING_PLAN) {
            this.setState({
                legendIsVisible: true
            });
        }
    }

    /**
     * Hide the [Legend] component when the specified layer is enabled from the LayersControl map element.
     *
     * @param layersControlEvent leaflet layers control event with properties from the base Event.
     */
    private removeMapLegend = (layersControlEvent: LayersControlEvent) => {
        if (layersControlEvent.name === BasicOverlayName.ZONING_PLAN) {
            this.setState({
                legendIsVisible: false
            });
        }
    }

    /**
     * This method runs after the component output has been rendered to the DOM.
     */
    componentDidMount() {
        // Add zoom controls to the map in the desired position
        if (this._refs.map.current) {
            L.control.zoom({
                position: 'bottomright'
            }).addTo(this._refs.map.current.leafletElement);
        }
    }

    render() {
        return (
            <Map
                ref={this._refs.map}
                center={SETTINGS.center}
                zoom={SETTINGS.defaultMapZoom}
                maxZoom={SETTINGS.maxMapZoom}
                zoomControl={false}
                doubleClickZoom={true}
                scrollWheelZoom={true}
                dragging={true}
                animate={true}
                easeLinearity={0.35}
                onoverlayadd={this.addMapLegend}
                onoverlayremove={this.removeMapLegend}
                onmoveend={this.moveEndHandler}
                onzoomend={this.zoomEndHandler}
                onbaselayerchange={this.baseLayerChangeHandler}
            >
                <LayersControl position="topright">
                    {Object.values(BasicBaseLayer).map((baseLayer, index) =>
                        <BaseLayer key={index}
                                   checked={this.selectedBaseLayer === baseLayer}
                                   name={baseLayer.name}>
                            <TileLayer
                                attribution={baseLayer.attribution}
                                url={baseLayer.url}
                            />
                        </BaseLayer>
                    )}
                    <Overlay name={BasicOverlayName.BUILDINGS}>
                        <LayerGroup ref={this._refs.bagLayer}>
                            <WMSTileLayer
                                maxZoom={SETTINGS.maxTileZoom}
                                layers="pand"
                                format="image/png"
                                transparent={true}
                                url="https://geodata.nationaalgeoregister.nl/bag/wms/v1_1?request=getCapabilities&service=WMS"
                            />
                        </LayerGroup>
                    </Overlay>

                    <Overlay name={BasicOverlayName.PLOTS}>
                        <LayerGroup ref={this._refs.brkLayer}>
                            <WMSTileLayer
                                maxZoom={SETTINGS.maxTileZoom}
                                layers="Perceel"
                                format="image/png"
                                transparent={true}
                                url="https://geodata.nationaalgeoregister.nl/kadastralekaart/wms/v4_0?service=WMS&version=1.3.0&request=GetCapabilities"
                            />
                        </LayerGroup>
                    </Overlay>

                    <Overlay name={BasicOverlayName.ZONING_PLAN}>
                        <LayerGroup>
                            <WMSTileLayer
                                maxZoom={SETTINGS.maxTileZoom}
                                layers="Enkelbestemming"
                                format="image/png"
                                transparent={true}
                                styles='plu:Enkelbestemming'
                                url="https://geodata.nationaalgeoregister.nl/plu/ows?service=WMS&request=GetMap"
                            />

                            <WMSTileLayer
                                maxZoom={SETTINGS.maxTileZoom}
                                layers="Bestemmingsplangebied"
                                format="image/png"
                                transparent={true}
                                url="https://geodata.nationaalgeoregister.nl/plu/ows?service=WMS&request=GetMap"
                            />
                        </LayerGroup>
                    </Overlay>
                </LayersControl>

                <Searchbar map={this._refs.map}/>

                <Legend isVisible={!this.state.legendIsVisible}/>
            </Map>
        );
    }
}