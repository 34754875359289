import React from "react";
import Feature from "../models/Feature";
import "../styles/landPlotPopup.scss";

export interface LandPlotPopupProps {
    feature: Feature
}

export interface LandPlotPopupState {
    
}

export default class LandPlotPopup extends React.Component<LandPlotPopupProps, LandPlotPopupState> {

    constructor(props: LandPlotPopupProps) {
        super(props);
    }

    render() {
        const {feature} = this.props;

        const tableData = [
            ["Perceelnummer", feature.properties.perceelnummer],
            ["Perceel grootte", feature.properties.kadastraleGrootteWaarde],
            ["Tijdstop registratie", feature.properties.tijdstipRegistratie],
            ["Status historie", feature.properties.statusHistorieWaarde],
            ["Gemeente, sectie", `${feature.properties.kadastraleGemeenteWaarde}, ${feature.properties.sectie}`]
        ]

        return (
            <div>
                <h5>Kadastraal Perceel</h5><br/>
                <table>
                    {tableData.map((rowData, index) =>
                        <tr key={index} className="land-plot-data-row">
                            <td className="font-weight-bold">{rowData[0]}:</td>
                            <td>{rowData[1]}</td>
                        </tr>
                    )}
                </table>
            </div>
        );
    }


}