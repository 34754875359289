import React, {Component} from "react";
import MapView from "./components/MapView";

class App extends Component {
  render() {
    return (
        <MapView/>
    );
  }
}

export default App;
